const richtextRenderer = require('storyblok-rich-text-react-renderer')
const {
  NODE_PARAGRAPH,
  NODE_HEADING,
  NODE_CODEBLOCK,
  NODE_QUOTE,
  NODE_OL,
  NODE_UL,
  NODE_LI,
  NODE_HR,
  NODE_BR,
} = richtextRenderer

const renderNode = (node, addNewlines = true) => {
  if (node.type === 'text') {
    return node.text
  } else if (
    [
      NODE_PARAGRAPH,
      NODE_HEADING,
      NODE_CODEBLOCK,
      NODE_QUOTE,
      NODE_OL,
      NODE_UL,
      NODE_LI,
      NODE_HR,
      NODE_BR,
    ].includes(node.type)
  ) {
    return node.content?.length
      ? `${renderNodes(node.content, addNewlines)}${addNewlines ? '\n\n' : ' '}`
      : ''
  }

  return null
}

const renderNodes = (nodes, addNewlines = true) =>
  nodes
    .map((node) => renderNode(node, addNewlines))
    .filter((node) => node !== null)
    .join('')
    // Replace multiple spaces with one
    .replace(/[^\S\r\n]{2,}/g, ' ')

const isRichtext = (field) => {
  return field?.content?.length
}

const isValidTextContent = (field) => {
  return typeof field === 'string' || isRichtext(field)
}

const getPlainText = (richtext, { addNewlines = true } = {}) => {
  if (!isRichtext(richtext)) {
    return isValidTextContent(richtext) ? richtext : undefined
  }
  const text = renderNodes(richtext.content, addNewlines)
  return text
}

const sanitizeStoryblokContent = (content) => {
  if (typeof content !== 'string') return content

  const sanitizedContent = content
    // Remove LS characters showing up on windows machines as `L SEP``
    // https://stackoverflow.com/questions/41555397/strange-symbol-shows-up-on-website-l-sep
    .replace(/\u2028/g, '')
  return sanitizedContent
}

exports.sanitizeStoryblokContent = sanitizeStoryblokContent
exports.getPlainText = getPlainText
