import React from 'react'
import PropTypes from 'prop-types'
import { storyblokEditable } from 'gatsby-source-storyblok'
import { cn, addHtmlProps } from '../../utils'
import { HtmlSectionElements } from '../../constants'
import Richtext from './Richtext'
import UnstyledHeading from './UnstyledHeading'
import UnstyledImage from './UnstyledImage'
import UnstyledLink from './UnstyledLink'
import ActionGroup from './ActionGroup'

const FeaturedCard = ({
  as: Tag,
  className,
  blok,
  headlineOrder,
  ...props
}) => {
  const hasImage = blok.image?.filename
  const hasContent = Boolean(
    blok.title || blok.description || blok.tags?.length,
  )
  const hasColumns = hasImage && hasContent
  const link = blok.links?.[0]
  if (link) {
    Tag = UnstyledLink
  }

  return (
    <Tag
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={cn(
        'relative bg-sand-100 rounded',
        hasColumns && 'md:flex',
        className,
      )}
      link={link?.link}
      {...addHtmlProps(blok)}
    >
      {hasImage && (
        <div className={cn('relative', hasColumns && 'basis-1/2 md:order-1')}>
          <div className="aspect-16/9" />
          <div>
            <UnstyledImage
              image={blok.image}
              className="!absolute inset-0 w-full h-full object-cover mdmax:rounded-t md:rounded-r"
              imgClassName="mdmax:rounded-t md:rounded-r"
            />
          </div>
        </div>
      )}
      {hasContent && (
        <div
          className={cn(
            'p-6 md:py-10 md:flex md:flex-col',
            hasColumns && 'basis-1/2',
          )}
        >
          {blok.title && (
            <UnstyledHeading
              order={headlineOrder}
              className={cn(
                'font-semibold whitespace-pre-line text-20 xl:text-26',
              )}
            >
              <span>{blok.title}</span>
            </UnstyledHeading>
          )}
          {blok.description && (
            <Richtext
              document={blok.description}
              className={cn(
                'text-14 xl:text-16 2xl:text-18',
                blok.title && 'mt-3',
              )}
            />
          )}
          {blok.tags?.length > 0 && (
            <div className={cn((blok.title || blok.description) && 'mt-6')}>
              {/* TODO: Use Tag/Chip component; create global ChipGroup component */}
              <ul className="-mt-2 -mr-2">
                {blok.tags.map((tag) => (
                  <li
                    key={tag.text}
                    className="mr-2 mt-2 inline-flex max-w-full"
                  >
                    <span className="inline-flex whitespace-nowrap max-w-full truncate text-forest border border-forest rounded px-3 xl:px-4 py-[0.54em] text-14">
                      {tag.text}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {blok.links?.length > 0 && (
            <ActionGroup
              items={blok.links}
              itemsAs="div"
              className={cn(hasContent && 'mt-auto pt-10')}
            />
          )}
        </div>
      )}
    </Tag>
  )
}

FeaturedCard.propTypes = {
  as: PropTypes.any,
  className: PropTypes.string,
  blok: PropTypes.object,
  headlineOrder: PropTypes.number,
}

FeaturedCard.defaultProps = {
  as: HtmlSectionElements[0],
  blok: {},
  headlineOrder: 2,
}

export default FeaturedCard
