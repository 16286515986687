import * as React from 'react'
import { graphql } from 'gatsby'
import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from 'gatsby-source-storyblok'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ProductGrid from '../components/shared/ProductGrid'
import UnstyledRichtext from '../components/shared/UnstyledRichtext'
import { getLangFromContext, filterCurrentLocale } from '../utils'
const { sanitizeStoryblokContent } = require('../api/storyblok-utils')

const ProductOverviewPage = ({ pageContext, location, data }) => {
  const lang = getLangFromContext(pageContext)
  let story = pageContext?.story
  story = useStoryblokState(story)

  const blok = story.content

  const components =
    story.content.body?.map((blok) => (
      <StoryblokComponent blok={blok} key={blok._uid} />
    )) || []

  const products = data.products.edges.map((product) => {
    const content = product.node.content
    const newContent =
      typeof content === 'string'
        ? JSON.parse(sanitizeStoryblokContent(content))
        : content
    product.node.content = newContent
    return product.node
  })

  const featuredCards = story?.content?.featuredCards

  const processingOptions = filterCurrentLocale(
    data.processingOptions?.nodes,
    lang,
  )
  const materialOptions = filterCurrentLocale(data.materialOptions?.nodes, lang)
  const endOfLifeOptions = filterCurrentLocale(
    data.endOfLifeOptions?.nodes,
    lang,
  )

  return (
    <Layout context={pageContext} location={location}>
      <main {...storyblokEditable(story.content)}>
        <div className="box bg-sand-100 pt-30 pb-10 xl:pb-20">
          <h1 className="font-heading font-bold uppercase text-forest leading-none text-48 md:text-56 lg:text-72 xl:text-96 whitespace-pre-line">
            {blok.title}
          </h1>
          {blok.copy && (
            <UnstyledRichtext
              document={blok.copy}
              className="mt-6 text-20 xl:text-26 lg:w-1/2 lg:max-w-[33rem]"
            />
          )}
        </div>
        <div>
          <ProductGrid
            products={products}
            featuredCards={featuredCards}
            processingOptions={processingOptions}
            materialOptions={materialOptions}
            endOfLifeOptions={endOfLifeOptions}
            blok={story?.content}
          />
        </div>
        {components}
      </main>
    </Layout>
  )
}

export default ProductOverviewPage

export const Head = ({ pageContext, location }) => {
  const storyContent = pageContext?.story?.content || {}

  return (
    <Seo
      location={location}
      seo={storyContent.seo}
      noindex={storyContent.noindex}
      ignorePageTitleTemplate={storyContent.ignorePageTitleTemplate}
      siteConfig={pageContext?.siteConfig}
    />
  )
}

export const query = graphql`
  query ($lang: String!, $langCode: String!) {
    locales: allLocale(filter: { language: { eq: $langCode } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    products: allStoryblokEntry(
      filter: { field_component: { glob: "(Product)" }, lang: { eq: $lang } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          internalId
          full_slug
          content
        }
      }
    }
    endOfLifeOptions: allStoryblokDatasourceEntry(
      filter: { data_source: { eq: "product-end-of-life" } }
    ) {
      nodes {
        ...DatasourceEntry
      }
    }
    materialOptions: allStoryblokDatasourceEntry(
      filter: { data_source: { eq: "product-material" } }
    ) {
      nodes {
        ...DatasourceEntry
      }
    }
    processingOptions: allStoryblokDatasourceEntry(
      filter: { data_source: { eq: "product-processing" } }
    ) {
      nodes {
        ...DatasourceEntry
      }
    }
  }
`
