import React from 'react'
import PropTypes from 'prop-types'
import { storyblokEditable } from 'gatsby-source-storyblok'
import { cn, addHtmlProps } from '../../utils'
import { HtmlSectionElements } from '../../constants'
import UnstyledHeading from './UnstyledHeading'
import UnstyledImage from './UnstyledImage'

const ProductCard = ({
  as: Tag,
  className,
  blok,
  headlineOrder,
  href,
  linkState,
  ...props
}) => {
  const title = blok.shortTitle || blok.title
  const description = blok.shortDescription || blok.description
  const productFamily = blok.productFamily || 'Bioplast'
  const animates = blok.tags?.length > 0
  const contentAnimationClasses = `hovers:translate-y-13 hovers:transition hovers:duration-400 hovers:group-hover:translate-y-0 hovers:will-change-transform hovers:translate-3d`
  const contentTranslateMargin = `hovers:-mt-13`

  return (
    <Tag
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={cn(
        'group relative overflow-hidden bg-sand-100 hovers:transition-colors hovers:hover:bg-moss-400 rounded',
        className,
      )}
      href={href}
      state={linkState}
      {...addHtmlProps(blok)}
    >
      <div
        className={cn(
          'relative mx-auto max-w-[20rem] pt-6 px-6',
          animates &&
            'hovers:transition hovers:duration-400 hovers:group-hover:scale-75 hovers:origin-top hovers:will-change-transform hovers:translate-3d',
        )}
      >
        <div className="relative aspect-1/1">
          {blok.thumbnail?.filename && (
            <UnstyledImage
              image={blok.thumbnail}
              objectFit="contain"
              className="!absolute inset-0 w-full h-full drop-shadow-[-10px_10px_70px_rgba(0,0,0,0.15)]"
              sizes="272px"
            />
          )}
        </div>
      </div>
      <div className={cn('pt-4 pb-6 px-6', animates && contentTranslateMargin)}>
        {title && (
          <UnstyledHeading
            order={headlineOrder}
            className={cn(
              'font-semibold whitespace-pre-line text-20 2xl:text-26',
              animates && contentAnimationClasses,
            )}
          >
            <span>{productFamily}</span> {/*<br className="mdmax:hidden" />*/}
            <span>{title}</span>
          </UnstyledHeading>
        )}
        {description && (
          <p
            className={cn(
              'text-14 mt-4 xl:text-16',
              animates && contentAnimationClasses,
              animates && 'hovers:group-hover:delay-[20ms]',
            )}
          >
            {description}
          </p>
        )}
        {blok.tags?.length > 0 && (
          <div
            className={cn(
              'mt-4 hovers:opacity-0 hovers:transition hovers:duration-400 hovers:group-hover:opacity-100',
              animates && contentAnimationClasses,
              animates && 'hovers:group-hover:delay-[40ms]',
            )}
          >
            <ul className="-mr-2 whitespace-nowrap truncate">
              {blok.tags.map((tag) => (
                <li key={tag.text} className="mr-2 inline-flex max-w-full">
                  <span className="inline-block whitespace-nowrap max-w-full truncate text-forest border border-forest rounded px-3 xl:px-4 py-[0.54em] text-14">
                    {tag.text}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Tag>
  )
}

ProductCard.propTypes = {
  as: PropTypes.any,
  className: PropTypes.string,
  blok: PropTypes.object,
  link: PropTypes.object,
  headlineOrder: PropTypes.number,
}

ProductCard.defaultProps = {
  as: HtmlSectionElements[0],
  blok: {},
  headlineOrder: 2,
}

export default ProductCard
