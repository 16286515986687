import React from 'react'
import PropTypes from 'prop-types'

import { HtmlSectionElements } from '../../constants'
import { addHtmlProps, cn } from '../../utils'
import Chip from './Chip'
import UnstyledHeading from './UnstyledHeading'

const FilterScale = ({
  as: Tag,
  className,
  title,
  headlineOrder,
  value,
  options,
  onChange,
  ...props
}) => {
  return (
    <div className={cn('flex-none', className)} {...addHtmlProps(props)}>
      {title && (
        <UnstyledHeading
          order={headlineOrder}
          className="text-earth-700 whitespace-pre-line text-14 xl:text-16 mb-3 sticky left-0"
        >
          {title}
        </UnstyledHeading>
      )}
      <div className="mdmax:overflow-x-auto mdmax:scrollbar-hidden">
        <ul className="inline-flex bg-sand-100 rounded overflow-hidden">
          {options.map((option, index) => (
            <li
              key={option.text}
              className={cn(
                'flex-none',
                index > 0 &&
                  'relative inline-flex before:inline-block before:bg-white before:w-px before:h-full',
              )}
            >
              <Chip
                text={option.text}
                variant="scale"
                checked={value === option.value}
                onChange={() =>
                  onChange(value === option.value ? 0 : option.value)
                }
                className={cn('relative z-10')}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

FilterScale.propTypes = {
  as: PropTypes.oneOf(HtmlSectionElements),
  className: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.array,
  headlineOrder: PropTypes.number,
}

FilterScale.defaultProps = {
  as: HtmlSectionElements[0],
  options: [],
}

export default FilterScale
