import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { encodeQueryParams, createEnumArrayParam } from 'serialize-query-params'
import { stringify, parse as parseQueryParams } from 'query-string'

import { HtmlSectionElements } from '../../constants'
import { addHtmlProps, cn } from '../../utils'
import ProductCard from './ProductCard'
import FeaturedCard from './FeaturedCard'
import UnstyledLink from './UnstyledLink'
import ProductFilter from './ProductFilter'
import Button from './Button'

const TOTAL_FILTERS = 4

const ProductGrid = ({
  as: Tag,
  className,
  products,
  featuredCards,
  materialOptions,
  processingOptions,
  endOfLifeOptions,
  blok,
  ...props
}) => {
  const finalFeaturedCards = featuredCards.filter(
    (card) => !card.hidden || card.hidden === 'undefined',
  )
  const allProducts = products.map((product) => ({
    ...product.content,
    fullSlug: product.full_slug,
    key: product.internalId,
  }))
  const uniqueBcsValues = [
    ...new Set(
      allProducts.map((product) => {
        const raw = parseInt(Number(product.biobasedCarbonShare))
        const value = Math.floor(raw / 10) * 10
        return value
      }),
    ),
  ]
  const location = useLocation()

  // Create query param types
  const ProcessingParam = createEnumArrayParam(
    processingOptions.map(({ name }) => name),
  )
  const EndOfLifeParam = createEnumArrayParam(
    endOfLifeOptions.map(({ name }) => name),
  )
  const MaterialParam = createEnumArrayParam(
    materialOptions.map(({ name }) => name),
  )
  const BcsParam = createEnumArrayParam(
    ['0', ...uniqueBcsValues.sort()].map((value) => String(value)),
  )

  // Init states
  const [filterActive, setFilterActive] = useState(false)
  const [items, setItems] = useState(allProducts)
  const [closestMatchFilter, setClosestMatchFilter] = useState(null)
  const [bcsFilter, setBcsFilter] = useState(0)
  const [processingFilter, setProcessingFilter] = useState([])
  const [endOfLifeFilter, setEndOfLifeFilter] = useState([])
  const [materialFilter, setMaterialFilter] = useState([])

  // Filter logic
  const updateFilter = ({ filterFunc, values }) => {
    setFilterActive(true)
    filterFunc(values)
  }

  const updateItems = (updatedItems) => {
    setItems(updatedItems)
  }

  const resetFilters = () => {
    setFilterActive(false)
    setProcessingFilter([])
    setEndOfLifeFilter([])
    setMaterialFilter([])
    setBcsFilter(0)
    setItems(allProducts)
  }

  const applyClosestMatchFilter = () => {
    if (closestMatchFilter && closestMatchFilter.length === TOTAL_FILTERS) {
      const [processingFilter, bcsFilter, endOfLifeFilter, materialFilter] =
        closestMatchFilter
      setProcessingFilter(processingFilter)
      setBcsFilter(bcsFilter)
      setEndOfLifeFilter(endOfLifeFilter)
      setMaterialFilter(materialFilter)
    } else {
      resetFilters()
    }
  }

  useEffect(() => {
    // Set initial filter values from query
    const queryParams = parseQueryParams(location.search)
    const decodedQuery = encodeQueryParams(
      {
        bcs: BcsParam,
        proc: ProcessingParam,
        eol: EndOfLifeParam,
        mat: MaterialParam,
      },
      queryParams,
    )
    const { bcs, proc, eol, mat } = decodedQuery

    if (bcs && bcs.length) {
      const bcsValue = parseInt(Number(bcs[0]))
      updateFilter({ filterFunc: setBcsFilter, values: bcsValue })
    }
    if (proc && proc.length) {
      updateFilter({ filterFunc: setProcessingFilter, values: proc })
    }
    if (eol && eol.length) {
      updateFilter({ filterFunc: setEndOfLifeFilter, values: eol })
    }
    if (mat && mat.length) {
      updateFilter({ filterFunc: setMaterialFilter, values: mat })
    }
  }, [])

  useEffect(() => {
    const filterMethods = [
      ...(processingFilter.length
        ? [(item) => item.processing.some((v) => processingFilter.includes(v))]
        : []),
      ...(endOfLifeFilter.length
        ? [(item) => item.endOfLife.some((v) => endOfLifeFilter.includes(v))]
        : []),
      ...(materialFilter.length
        ? [(item) => materialFilter.includes(item.material)]
        : []),
      ...(bcsFilter > 0
        ? [(item) => item.biobasedCarbonShare >= bcsFilter]
        : []),
    ]

    const filteredItems = allProducts.filter((item) => {
      for (let i = 0; i < filterMethods.length; i++) {
        if (!filterMethods[i](item)) {
          return false
        }
      }
      return true
    })

    if (filteredItems.length) {
      setClosestMatchFilter([
        processingFilter,
        bcsFilter,
        endOfLifeFilter,
        materialFilter,
      ])
    }

    const encodedQuery = encodeQueryParams(
      {
        bcs: BcsParam,
        proc: ProcessingParam,
        eol: EndOfLifeParam,
        mat: MaterialParam,
      },
      {
        bcs: [String(bcsFilter)],
        proc: processingFilter,
        eol: endOfLifeFilter,
        mat: materialFilter,
      },
    )
    const searchParams = `?${stringify(encodedQuery)}`
    const path = `${location.pathname}${searchParams}`
    if (window.location.search !== searchParams) {
      navigate(path, {
        // Save the last scroll position before updating the filter to restore it and
        // avoid jumping to the top of the page.
        state: { scrollPosition: window.scrollY },
        replace: true,
      })
    }
    updateItems(filteredItems)
  }, [processingFilter, bcsFilter, endOfLifeFilter, materialFilter])

  // Due to the focus management of the Reach Router (usefull accessibility
  // feature), we need to restore the last scroll position.
  // @see https://reach.tech/router/accessibility
  const scrollY = location?.state?.scrollPosition
  if (scrollY) {
    setTimeout(() => {
      window.scroll({
        top: scrollY,
        left: 0,
        behavior: 'auto',
      })
    })
  }

  return (
    <div
      className={cn('box bg-white py-10', className)}
      {...addHtmlProps(props)}
    >
      <ProductFilter
        processingOptions={processingOptions}
        bcsOptions={uniqueBcsValues}
        materialOptions={materialOptions}
        endOfLifeOptions={endOfLifeOptions}
        processingFilter={processingFilter}
        bcsFilter={bcsFilter}
        endOfLifeFilter={endOfLifeFilter}
        materialFilter={materialFilter}
        changeProcessingFilter={(values) =>
          updateFilter({ filterFunc: setProcessingFilter, values })
        }
        changeBcsFilter={(values) =>
          updateFilter({ filterFunc: setBcsFilter, values })
        }
        changeEndOfLifeFilter={(values) =>
          updateFilter({ filterFunc: setEndOfLifeFilter, values })
        }
        changeMaterialFilter={(values) =>
          updateFilter({ filterFunc: setMaterialFilter, values })
        }
        resetFilters={resetFilters}
        processingFilterTitle={blok.processingFilterTitle}
        bcsFilterTitle={blok.bcsFilterTitle}
        materialFilterTitle={blok.materialFilterTitle}
        endOfLifeFilterTitle={blok.endOfLifeFilterTitle}
        showFiltersText={blok.showFiltersText}
        hideFiltersText={blok.hideFiltersText}
        resetFiltersText={blok.resetFiltersText}
      />
      {/* Not working with filtered results sm:grid-cols-[repeat(auto-fit,minmax(300px,1fr))] */}
      <div className="mt-10 grid gap-4 ms:gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 3xl:lg:grid-cols-5">
        {finalFeaturedCards?.length > 0 &&
          !filterActive &&
          finalFeaturedCards.map((blok) => (
            <FeaturedCard
              key={blok._uid}
              blok={blok}
              className="sm:col-span-2"
            />
          ))}
        {items?.length > 0 &&
          items.map((blok) => (
            <ProductCard
              as={UnstyledLink}
              blok={blok}
              key={blok.key}
              href={blok.fullSlug}
              linkState={{ referringFromProductsPage: true }}
            />
          ))}
        {items?.length === 0 && filterActive && (
          <div className="rounded px-6 py-20 bg-sand-100 text-center col-start-1 col-end-[-1]">
            <svg
              viewBox="0 0 80 80"
              className="mx-auto w-20 h-20 fill-stone-400"
            >
              <path d="M37.334 64.916L20 54.834C19.2227 54.334 18.6113 53.6947 18.166 52.916C17.722 52.1387 17.5 51.278 17.5 50.334V30.166C17.5 29.222 17.722 28.3613 18.166 27.584C18.6113 26.8053 19.2227 26.166 20 25.666L37.334 15.5C38.1673 15.056 39.056 14.834 40 14.834C40.944 14.834 41.8327 15.056 42.666 15.5L60 25.666C60.7773 26.166 61.3887 26.8053 61.834 27.584C62.278 28.3613 62.5 29.222 62.5 30.166V50.334C62.5 51.278 62.278 52.1387 61.834 52.916C61.3887 53.6947 60.7493 54.334 59.916 54.834L42.5 64.916C41.6667 65.416 40.792 65.666 39.876 65.666C38.9587 65.666 38.1113 65.416 37.334 64.916ZM37.916 60.416V41.416L21.666 32.166V50.25C21.666 50.4167 21.708 50.5833 21.792 50.75C21.8747 50.9167 21.9993 51.0553 22.166 51.166L37.916 60.416ZM42.084 60.416L57.834 51.166C58.0007 51.0553 58.1253 50.9167 58.208 50.75C58.292 50.5833 58.334 50.4167 58.334 50.25V32.166L42.084 41.416V60.416ZM5 19.584V13.666C5 11.278 5.84733 9.23667 7.542 7.542C9.23667 5.84733 11.278 5 13.666 5H19.584V9.166H13.666C12.3887 9.166 11.3193 9.59667 10.458 10.458C9.59667 11.3193 9.166 12.3887 9.166 13.666V19.584H5ZM13.666 75C11.278 75 9.23667 74.1527 7.542 72.458C5.84733 70.7633 5 68.722 5 66.334V60.416H9.166V66.334C9.166 67.6113 9.59667 68.6807 10.458 69.542C11.3193 70.4033 12.3887 70.834 13.666 70.834H19.584V75H13.666ZM60.416 74.5V70.334H66.334C67.6113 70.334 68.6807 69.9033 69.542 69.042C70.4033 68.1807 70.834 67.1113 70.834 65.834V60H75V65.834C75 68.222 74.1527 70.2633 72.458 71.958C70.7633 73.6527 68.722 74.5 66.334 74.5H60.416ZM70.834 19.584V13.666C70.834 12.3887 70.4033 11.3193 69.542 10.458C68.6807 9.59667 67.6113 9.166 66.334 9.166H60.416V5H66.334C68.722 5 70.7633 5.84733 72.458 7.542C74.1527 9.23667 75 11.278 75 13.666V19.584H70.834ZM40 37.666L56.25 28.334L40.5 19.334C40.3333 19.222 40.1667 19.166 40 19.166C39.8333 19.166 39.6667 19.222 39.5 19.334L23.75 28.334L40 37.666Z" />
            </svg>
            <div className="mt-6 md:text-18 lg:text-20 xl:text-26">
              {blok.noResultsCopy}
            </div>
            <div className="mt-10 mdmax:space-y-2 md:flex md:justify-center md:space-x-4">
              <div>
                <Button
                  as="button"
                  variant="contained"
                  onClick={() => applyClosestMatchFilter()}
                >
                  {blok.noResultsClosestMatch}
                </Button>
              </div>
              <div>
                <Button as="button" variant="outlined">
                  {blok.noResultsContact}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ProductGrid.propTypes = {
  as: PropTypes.oneOf(HtmlSectionElements),
  className: PropTypes.string,
  products: PropTypes.array,
  featuredCards: PropTypes.array,
}

ProductGrid.defaultProps = {
  as: HtmlSectionElements[0],
  products: [],
  featuredCards: [],
}

export default ProductGrid
