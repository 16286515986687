import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { HtmlSectionElements } from '../../constants'
import { addHtmlProps, cn } from '../../utils'
import FilterGroup from './FilterGroup'
import FilterScale from './FilterScale'
import Hyperlink from './Hyperlink'
import Icon from './Icon'

const ScaleSteps = 10
const ScaleStart = 20

const ProductFilter = ({
  as: Tag,
  className,
  materialOptions,
  processingOptions,
  endOfLifeOptions,
  processingFilter,
  endOfLifeFilter,
  materialFilter,
  changeProcessingFilter,
  changeEndOfLifeFilter,
  changeBiobasedFilter,
  changeMaterialFilter,
  bcsOptions,
  bcsFilter,
  changeBcsFilter,
  resetFilters,
  processingFilterTitle,
  bcsFilterTitle,
  endOfLifeFilterTitle,
  materialFilterTitle,
  showFiltersText,
  hideFiltersText,
  resetFiltersText,
  ...props
}) => {
  const [showFilters, setShowFilters] = useState(false)

  const updateFilter = ({ filterFunc, value }) => {
    if (filterFunc) {
      filterFunc(value)
    }
  }

  const [maxValue] = bcsOptions.sort().reverse()
  const scaleValues = []
  let i = ScaleStart
  while (i <= maxValue) {
    scaleValues.push(i)
    i += ScaleSteps
  }
  const bcsScale = scaleValues.map((value) => ({
    value,
    text: `> ${value}%`,
  }))

  const [totalFilters] = useMemo(() => {
    const totalFilters =
      processingFilter.length +
      endOfLifeFilter.length +
      materialFilter.length +
      (bcsFilter > 0 ? 1 : 0)
    return [totalFilters]
  }, [processingFilter, endOfLifeFilter, materialFilter, bcsFilter])

  return (
    <div className={cn('', className)} {...addHtmlProps(props)}>
      <FilterGroup
        title={processingFilterTitle}
        options={processingOptions}
        activeOptions={processingFilter}
        onChange={(value) =>
          updateFilter({ filterFunc: changeProcessingFilter, value })
        }
      />
      {showFilters && (
        <div className="pt-6 mdmax:space-y-6 md:flex md:space-x-10">
          <FilterScale
            title={bcsFilterTitle}
            options={bcsScale}
            value={bcsFilter}
            onChange={(value) =>
              updateFilter({ filterFunc: changeBcsFilter, value })
            }
          />
          <FilterGroup
            title="End of life options"
            options={endOfLifeOptions}
            activeOptions={endOfLifeFilter}
            onChange={(value) =>
              updateFilter({ filterFunc: changeEndOfLifeFilter, value })
            }
          />
          <FilterGroup
            title={materialFilterTitle}
            options={materialOptions}
            activeOptions={materialFilter}
            onChange={(value) =>
              updateFilter({ filterFunc: changeMaterialFilter, value })
            }
          />
        </div>
      )}
      <div className="mt-8">
        <div className="-mt-4 -ml-4 space-x-4 space-y-4 flex flex-wrap justify-between">
          <button
            type="button"
            className="mt-4 ml-4 inline-flex items-center space-x-1 whitespace-nowrap pb-[0.4286em] border-b border-dashed border-current text-14 xl:text-16 text-earth-700 hovers:hover:text-stone-700 transition-colors"
            aria-pressed={showFilters}
            onClick={() => setShowFilters(!showFilters)}
          >
            {!showFilters && <span>{showFiltersText}</span>}
            {showFilters && <span>{hideFiltersText}</span>}
            <Icon
              name="expand_more"
              className={cn('transition', showFilters && 'rotate-180')}
            />
          </button>
          {totalFilters > 0 && (
            <Hyperlink
              as="button"
              onClick={resetFilters}
              iconLeft="restart_alt"
              right={
                <span className="rounded-full border border-current px-[0.5em]">
                  {totalFilters}
                </span>
              }
              className="mt-4"
            >
              {resetFiltersText}
            </Hyperlink>
          )}
        </div>
      </div>
    </div>
  )
}

ProductFilter.propTypes = {
  as: PropTypes.oneOf(HtmlSectionElements),
  className: PropTypes.string,
}

ProductFilter.defaultProps = {
  as: HtmlSectionElements[0],
}

export default ProductFilter
