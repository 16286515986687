import React from 'react'
import PropTypes from 'prop-types'

import { HtmlSectionElements } from '../../constants'
import { addHtmlProps, cn } from '../../utils'
import Chip from './Chip'
import UnstyledHeading from './UnstyledHeading'

const FilterGroup = ({
  as: Tag,
  className,
  title,
  headlineOrder,
  options,
  activeOptions,
  onChange,
  ...props
}) => {
  const uncheckOption = (name) => {
    const remaining = activeOptions.filter((activeName) => name !== activeName)
    onChange(remaining)
  }

  const checkOption = (name) => {
    onChange([...activeOptions, name])
  }

  const toggleOptions = (name) => {
    if (activeOptions.includes(name)) {
      uncheckOption(name)
    } else {
      checkOption(name)
    }
  }

  return (
    <div className={cn('', className)} {...addHtmlProps(props)}>
      {title && (
        <UnstyledHeading
          order={headlineOrder}
          className="text-earth-700 whitespace-pre-line text-14 xl:text-16 mb-3"
        >
          {title}
        </UnstyledHeading>
      )}
      <div className="-mt-2 -mr-2">
        {options.map((option) => (
          <Chip
            key={option.name}
            text={option.value}
            checked={activeOptions.includes(option.name)}
            onChange={() => toggleOptions(option.name)}
            className="mt-2 mr-2"
          />
        ))}
      </div>
    </div>
  )
}

FilterGroup.propTypes = {
  as: PropTypes.oneOf(HtmlSectionElements),
  className: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.array,
  headlineOrder: PropTypes.number,
}

FilterGroup.defaultProps = {
  as: HtmlSectionElements[0],
  options: [],
}

export default FilterGroup
